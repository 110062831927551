import App from './app/app';
import {
  Analytics,
  AuthenticatedSection,
  AuthProvider,
} from '@readcloud/common';
import { AppDispatch, reportActions } from '@readcloud/state';
import { Toaster } from '@readcloud/ui';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { environment } from './environments/environment';
import { store } from './redux/store';
import { WebAppVersion, ContentVersion } from '@readcloud/common/config.json';
import useIsOnline from './hooks/useIsOnline';

interface IAppRootProps {}

const TAURI_ENVIRONMENT = !!(window as any).__TAURI__;

const AppRoot: React.FunctionComponent<IAppRootProps> = (props) => {
  const isOnline = useIsOnline();

  const [serviceWorkerReady, setServiceWorkerReady] = React.useState(
    !TAURI_ENVIRONMENT
  );

  const [wasOffline, setWasOffline] = React.useState(false);

  const checkServiceWorkerStatus = React.useCallback(() => {
    if ('serviceWorker' in navigator) {
      const waitForSWControl = async () => {
        // Wait for the service worker to be ready
        const registration = await navigator.serviceWorker.ready;
        console.log('Service worker is ready');

        if (navigator.serviceWorker.controller) {
          // Service worker is already controlling the page
          console.log('Service worker is already controlling the page');
          setServiceWorkerReady(true);
        } else {
          // For the initial installation, we need to wait for the service worker to activate
          console.log('Waiting for service worker to activate');
          await new Promise<void>((resolve) => {
            if (registration.active) {
              // If it's already active, resolve immediately
              resolve();
            } else {
              registration.addEventListener('activate', () => resolve(), {
                once: true,
              });
            }
          });

          // After activation, we need to reload the page to ensure the service worker controls it
          console.log('Service worker activated, reloading page');
          window.location.reload();
        }
      };

      waitForSWControl().catch((error) => {
        console.error('Error while waiting for service worker:', error);
      });
    } else {
      console.warn('Service workers are not supported in this browser');
      setServiceWorkerReady(true); // Allow the app to render even if service workers aren't supported, just means no offline support
    }
  }, []);

  React.useEffect(() => {
    if (TAURI_ENVIRONMENT) {
      checkServiceWorkerStatus();
    }
  }, [checkServiceWorkerStatus, TAURI_ENVIRONMENT]);

  React.useEffect(() => {
    if (!isOnline) {
      setWasOffline(true);
    } else if (wasOffline && !serviceWorkerReady) {
      // If we were offline and now we're online, but the service worker
      // is still not ready, reload the page
      window.location.reload();
    }
  }, [isOnline, wasOffline, serviceWorkerReady]);

  // Render the app only if the service worker is ready
  return serviceWorkerReady ? (
    <React.Suspense
      fallback={
        <div className="h-screen w-full grid place-content-center">
          Loading...
        </div>
      }
    >
      <Provider store={store}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <AuthProvider
              client_id={environment.auth.client_id}
              authority={environment.auth.authority}
              signinCallback={() => {
                (store.dispatch as AppDispatch)(
                  reportActions.asyncActions.createUserActivity({
                    type: 'Login',

                    'App Name': 'Webapp',
                    'Webapp Version': WebAppVersion,
                    'Content Version': ContentVersion,
                  })
                );
              }}
            >
              <Analytics apiKey={environment.posthog_id}>
                <AuthenticatedSection>
                  <App />
                </AuthenticatedSection>
                <div
                  style={{ position: 'absolute' }}
                  className="readcloud-ui readcloud-ui-reset"
                >
                  <Toaster />
                </div>
              </Analytics>
            </AuthProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  ) : (
    <div className="h-screen w-full grid place-content-center">
      <div className="text-2xl font-bold">Loading application...</div>
      <div className="text-lg">
        Please wait while we prepare the app for{' '}
        {isOnline ? 'use' : 'offline use'}.
      </div>
    </div>
  );
};

export default AppRoot;
